import React from 'react'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { Group, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const DepartmentSettingsFields = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = !useHasGlobalPermission([
    PermissionTypes.ChangeOrganisationPreferences,
  ])
  const karmaHidden = !values.karma?.visible

  return (
    <VStack mt="s-24" space="s-16">
      <Group>
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable Leadership functionality',
            description:
              'Enables managing who holds the key positions in the department and displaying this to other users',
          }}
          name="enable_leadership"
          disabled={disableEdit}
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable Budget Management functionality',
            description:
              'Allows tracking department spending and compensation management for the talent',
          }}
          name="enable_budget_management"
          disabled={disableEdit}
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enable Karma functionality',
            description:
              'Allows measuring the impact of the findings and risks on the company by using Karma points',
          }}
          name="karma.enabled"
          disabled={disableEdit}
          hidden={karmaHidden}
        />
      </Group>

      <ApprovalSteps
        title="Enable Departments approval"
        description="Whenever a new department is created or an existing one is changed it asks for approval"
        switchField="enable_departments_approvals"
        approvalProcess="departments_approval_steps"
        entity="department"
        disabled={disableEdit}
        formRef={values}
      />
    </VStack>
  )
}

export const DepartmentSettingsSaveButton = () => {
  const queryClient = useQueryClient()

  return (
    <NewSaveButtonWithPopup
      successText="Settings saved successfully"
      onAfterSubmit={() => {
        queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
        queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
      }}
      useValidator
    />
  )
}
