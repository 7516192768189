import React, { useMemo, useState } from 'react'
import { MoreBar, Token, VStack, useToggle, Widget, Box } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { SORT_DIRECTION } from '@src/interfaces/data'
import {
  useFetchInterviewAvailability,
  useInterviewerAvailabilityPreferences,
} from '@src/api/interviewAvailability'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getCurrentWeek, NavigationWeek } from '@src/components/NavigateWeek/NavigateWeek'
import { InterviewAvailabilityPreferencesSidebar } from '@src/features/InterviewAvailability/InterviewAvailabilityPreferencesSidebar'
import { InterviewAvailabilitySyncAction } from '@src/features/InterviewAvailability/InterviewAvailabilitySyncAction'
import { RequiresFeatureFlagWrapper } from '@src/components/RequiresFeatureFlagWrapper/RequiresFeatureFlagWrapper'
import { FeatureFlags } from '@src/store/auth/types'
import { LargeWeeklyCalendar } from '@components/LargeWeeklyCalendar'
import { getInterviewAvailabilityStatusText } from '@src/features/InterviewAvailability/InterviewAvailabilityStatus'
import { LARGE_WEEKLY_CALENDAR_MIN_WIDTH } from '@components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { useDynamicSlotsFeature } from '@src/features/InterviewAvailability/hooks/useDynamicSlotsFeature'
import { InterviewAvailabilityBanner } from '@src/features/InterviewAvailability/InterviewAvailabilityBanner'

const CALENDAR_PADDING = 32

const getWeekRangeFilter = (week: NavigationWeek) => {
  const range = `${week.start},${week.end}`
  return [
    {
      filters: [{ id: range, name: range }],
      columnName: 'event_start_datetime',
    },
  ]
}

export const InterviewAvailabilityTablePage = () => {
  const dynamicSlotsEnabled = useDynamicSlotsFeature()
  const [openInterviewAvailabilityPreferences, toggleInterviewAvailabilityPreferences] =
    useToggle({
      defaultState: true,
    })
  const employee = useSelector(selectUser)
  const employeeId = employee.id
  const [week, setWeek] = useState<NavigationWeek>(getCurrentWeek())
  const { data, refetch } = useFetchInterviewAvailability({
    filters: [
      {
        columnName: 'employee',
        filters: [{ id: employeeId, name: String(employeeId) }],
        nonResettable: true,
      },
      ...getWeekRangeFilter(week),
    ],
    sortBy: [
      {
        sortBy: 'event_start_datetime',
        direction: SORT_DIRECTION.DESC,
      },
    ],
  })
  const { data: preferences, refetch: refetchPreferences } =
    useInterviewerAvailabilityPreferences(employeeId)

  const events = useMemo(
    () =>
      data?.results.map(item => ({
        start: item.event_start_datetime,
        end: item.event_end_datetime,
        title: getInterviewAvailabilityStatusText(item.status),
        backgroundColor:
          item.status !== 'pending' ? Token.color.greyTone8 : Token.color.actionBlue,
        color: item.status !== 'pending' ? Token.color.greyTone50 : Token.color.black,
      })) || [],
    [data?.results],
  )

  return (
    <>
      {openInterviewAvailabilityPreferences && (
        <InterviewAvailabilityPreferencesSidebar
          employeeId={employeeId}
          onClose={() => {
            toggleInterviewAvailabilityPreferences()
          }}
          onSuccess={() => {
            refetchPreferences()
            refetch()
          }}
        />
      )}
      <PageWrapper>
        <PageHeader
          backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
          title={
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.title"
              defaultMessage="Interview availability"
            />
          }
          subtitle={
            dynamicSlotsEnabled ? (
              <FormattedMessage
                id="recruitment.interviewAvailability.tablePage.subtitleDynamicSlots"
                defaultMessage="By default, interviews can be booked 24hrs in advance in any free time in your calendar during your normal working hours. You can change this in availability preferences"
              />
            ) : (
              <FormattedMessage
                id="recruitment.interviewAvailability.tablePage.subtitle"
                defaultMessage="Specify weekly time slots when you prefer to take interviews"
              />
            )
          }
        />
        <PageBody maxWidth="100%">
          <VStack gap="s-16">
            <InterviewAvailabilityBanner
              showDefineSlotsBanner={preferences ? !preferences.use_dynamic_slots : false}
            />
            <Widget
              p="s-16"
              minWidth={LARGE_WEEKLY_CALENDAR_MIN_WIDTH + CALENDAR_PADDING}
            >
              <MoreBar>
                <RequiresFeatureFlagWrapper
                  flags={[FeatureFlags.ApplyInterviewsAvailabilityLimitsToSlots]}
                >
                  <MoreBar.Action
                    useIcon="Gear"
                    onClick={() => {
                      toggleInterviewAvailabilityPreferences()
                    }}
                  >
                    <FormattedMessage
                      id="recruitment.interviewAvailability.actions.availabilityPreferences"
                      defaultMessage="Availability preferences"
                    />
                  </MoreBar.Action>
                </RequiresFeatureFlagWrapper>
                <InterviewAvailabilitySyncAction
                  employeeId={employeeId}
                  onSuccess={() => {
                    refetch()
                  }}
                />
              </MoreBar>

              <Box mt="s-16">
                <LargeWeeklyCalendar
                  startDayOfWeek={1}
                  eventView={['time']}
                  events={events}
                  onSwitchWeek={(start, end) => {
                    setWeek({
                      start: start.toISOString(),
                      end: end.toISOString(),
                    })
                  }}
                  disabled
                  useWindowScroll
                />
              </Box>
            </Widget>
          </VStack>
        </PageBody>
      </PageWrapper>
    </>
  )
}
