import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import { ROUTES } from '@src/constants/routes'
import { organisationViewPermissions } from '@src/pages/Settings/SettingsLandingPage/constants'

import { StructuresSettingsAll } from './SettingsAll'
import { StructuresTeams } from './Teams'
import { StructuresRoles } from './Roles'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { PageNoAccessWidget } from '@src/features/Errors/components/Page/PageNoAccessWidget'

export const StructuresSettings = () => {
  const canView = useHasGlobalPermission(organisationViewPermissions)

  return (
    <Switch>
      {canView ? (
        <>
          <Route path={ROUTES.SETTINGS.STRUCTURES.ALL} exact>
            <StructuresSettingsAll />
          </Route>
          <Route path={ROUTES.SETTINGS.STRUCTURES.TEAMS.ANY}>
            <StructuresTeams />
          </Route>
          <Route path={ROUTES.SETTINGS.STRUCTURES.ROLES.ANY}>
            <StructuresRoles />
          </Route>
        </>
      ) : (
        <PageNoAccessWidget />
      )}
      <Route>
        <PageNotFoundWidget />
      </Route>
    </Switch>
  )
}
