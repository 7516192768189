import React, { useContext, useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { DeliverablesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/DeliverablesCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CriteriaAssessment,
  ReviewCategory,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Visibility } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Visibility'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { KPIsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/KPIsCard'
import { EmployeePerformanceInterface } from '@src/pages/Forms/EmployeePerformanceLayout/index'
import {
  getRecommendedGrade,
  RecommendedGradesInterface,
  useGetSegmentedDeliverablesEnabled,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import get from 'lodash/get'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { Statuses } from '@src/interfaces'
import { GradesPrefilledBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/RatingsPrefilledBanner'
import { ScorecardVisibilityBanner } from '@src/pages/Performance/Scorecard/common/ScorecardVisibilityBanner'

export const RecommendedGradesContext = React.createContext<RecommendedGradesInterface>({
  recommendedGrade: null,
  grades: null,
})

export const useRecommendedGradesContext = () => {
  return useContext(RecommendedGradesContext)
}

export const getRecommendationAndType = (values: ReviewScorecardInterface) => {
  const lmRecommendation = values.review_data.line_manager_extra_section
  const fmRecommendation = values.review_data.functional_manager_extra_section
  const peerRecommendation = values.review_data.peer_extra_section
  const probationPipRecommendation = values.review_data.recommendation_extra_section
  const obsoletePipRecommendation = values.review_data.pip_extra_section

  if (lmRecommendation) {
    return {
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
      field: 'review_data.line_manager_extra_section',
    }
  }
  if (fmRecommendation) {
    return {
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
      field: 'review_data.functional_manager_extra_section',
    }
  }
  if (peerRecommendation) {
    return {
      recommendation: peerRecommendation,
      type: RecommendationTypes.PEER,
      field: 'review_data.peer_extra_section',
    }
  }
  if (probationPipRecommendation) {
    return {
      recommendation: probationPipRecommendation,
      type: RecommendationTypes.PIP,
      field: 'review_data.recommendation_extra_section',
    }
  }
  if (obsoletePipRecommendation) {
    return {
      recommendation: obsoletePipRecommendation,
      type: RecommendationTypes.PIP_V1,
      field: 'review_data.pip_extra_section',
    }
  }

  return null
}

export const ScorecardContent = connect(
  ({ latestValues }: EmployeePerformanceInterface) => {
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()
    const recommendationSection = getRecommendationAndType(values)
    const { gradesMap: newGradesMap } = useGetReviewGradesMap()
    const gradesMap = values.grade_label_mapping || newGradesMap

    const isSegmentedDeliverables = useGetSegmentedDeliverablesEnabled()
    const isPerfReview = values.category === ReviewCategory.Performance

    const isCriteriaAssessment = (data: unknown): data is CriteriaAssessment =>
      (data as CriteriaAssessment)?.items !== undefined
    const section = isCriteriaAssessment(
      values.review_data.functional_skills?.cards?.[0].sections?.[0],
    )
      ? values.review_data.functional_skills?.cards?.[0].sections?.[0]
      : undefined
    const hasSkillsToPrefill = !section
      ? false
      : !!section.items?.[0].previous_values?.[0]?.value
    const showBanner = isPerfReview && hasSkillsToPrefill

    const grades = getRecommendedGrade(!!isSegmentedDeliverables, latestValues)

    const user = useSelector(selectUser)
    const reviews = useGetNotCompletedReviews(user.id)
    const reviewsList = reviews?.data?.results?.filter(
      review => review.status === Statuses.pending || review.status === Statuses.draft,
    )

    if (recommendationSection && grades.recommendedGrade) {
      const recommendation = get(
        values,
        recommendationSection.field,
      )?.employee_project_performance

      if (recommendation && !recommendation.value) {
        recommendation.value = grades.recommendedGrade
      }
    }

    const onHelpTooltipClick = (helpTab: HelpTabs) => {
      if (isHelpOpen) {
        setHelpOpen(undefined)
      }
      setHelpOpen(helpTab)
    }

    const commonProps = {
      onHelpClick: onHelpTooltipClick,
      gradesMap,
    }

    return (
      <RecommendedGradesContext.Provider value={grades}>
        <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
        <VStack space="s-16">
          <ScorecardVisibilityBanner
            category={values.category}
            reviewerRelation={values.reviewer_relation}
          />
          {showBanner && <GradesPrefilledBanner />}
          <KPIsCard {...commonProps} />
          <DeliverablesCard
            {...commonProps}
            isSegmentedDeliverables={isSegmentedDeliverables}
          />
          <SkillsCard {...commonProps} />
          <ManagerSkillsCard {...commonProps} />
          <CultureValuesCard {...commonProps} />
          {recommendationSection && (
            <BarRaiser
              isViewMode={false}
              questions={
                recommendationSection.recommendation.keeper_test_section.questions
              }
              type={recommendationSection.type}
              field={recommendationSection.field}
            />
          )}
          <AdditionalQuestions data={values.review_data} isViewMode={false} />
          <Feedback
            recommendationData={recommendationSection?.recommendation}
            type={recommendationSection?.type}
            gradesMap={gradesMap}
          />
          <Visibility />
          <PageActions maxWidth="100%">
            <Flex gap="s-8" justifyContent="center">
              <SaveDraftButton reviewsList={reviewsList} />
              <SubmitButton reviewsList={reviewsList} />
            </Flex>
          </PageActions>
        </VStack>
      </RecommendedGradesContext.Provider>
    )
  },
)
