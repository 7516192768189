import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { useSyncMeetings } from '@src/api/meetings'

export const HeaderActions = ({ calendarLink }: { calendarLink?: string | null }) => {
  const openNewTab = useOpenNewTab()

  const { mutateAsync: syncMeetings, isLoading } = useSyncMeetings()
  return (
    <MoreBar maxCount={2}>
      {calendarLink && (
        <MoreBar.Action
          onClick={() => {
            openNewTab(calendarLink)
          }}
          useIcon="Calendar"
        >
          Change Schedule
        </MoreBar.Action>
      )}
      <MoreBar.Action
        pending={isLoading}
        onClick={() => syncMeetings()}
        useIcon="ArrowExchange"
      >
        Sync latest meetings
      </MoreBar.Action>
    </MoreBar>
  )
}
