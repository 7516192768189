import React from 'react'
import SectionLayout from '@src/pages/Careers/CareersApplication/components/Section'
import { Box, Text, Token } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'

interface Props {
  checked: boolean
  onCheck: (checked: boolean) => void
}

export const TranscriptionConsent = ({ checked, onCheck }: Props) => {
  return (
    <Box mt="s-32">
      <SectionLayout
        title="Interview transcripts"
        body={
          <Box>
            <NewCheckbox
              checked={checked}
              label="I consent"
              onChange={e => {
                onCheck(e.currentTarget.checked)
              }}
            />
            <Text
              color={Token.color.greyTone50}
              variant="caption"
              display="block"
              mt="s-16"
            >
              Tick "I consent" above if you agree to us using AI to record a written
              transcript and summarise your interviews (video or audio will not be
              recorded). The transcripts and summaries will be used to help our
              interviewers fill in their interview scorecards, which means we will be able
              to assess you more fairly and provide you with more timely and accurate
              feedback.
              <br />
              <br />
              Consent is entirely optional and will not impact your employment prospects.
              You can withdraw your consent at any time by emailing us at dpo@revolut.com
              or by notifying your interviewer during any interview.
            </Text>
          </Box>
        }
      />
    </Box>
  )
}
