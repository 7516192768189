import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  useTooltip,
  Token,
  IconName,
  Text,
  chain,
  Item,
  Avatar,
  ActionButton,
  VStack,
  HStack,
  Icon,
  Flex,
} from '@revolut/ui-kit'
import React, { ReactNode } from 'react'
import { relationToString } from '@components/ScorecardGeneral/constants'
import {
  ReviewerRelation,
  ReviewSummaryInterface,
  SingleTimelineEventReviewInterface,
} from '@src/interfaces/performance'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'

import { Statuses } from '@src/interfaces'
import { GradedRequestInterface } from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { isObject } from 'lodash'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import StyledLink from '@src/components/Atoms/StyledLink'
import { isPast } from 'date-fns'
import { getColorsAndGrade } from '@src/components/PerformanceGrade/PerformanceGrade'
import { getInitials } from '@src/utils/employees'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { getAboveBelowSuggestedGrades } from '@src/pages/Forms/CommonTalentTab/utils'
import { CalibratedTooltipBody } from './CalibratedTooltipBody'

export const StepReviewersList = ({
  reviews,
  onClickAddReview,
  buttonDisabled,
  icon,
  title,
  performanceLink,
  subText,
  secondaryActionButton,
  reviewsPublishingDay,
  isCalibrationStep = false,
  summaryData,
}: {
  reviews: SingleTimelineEventReviewInterface[] | GradedRequestInterface[]
  onClickAddReview: (e: React.MouseEvent) => void
  buttonDisabled: boolean
  icon: IconName
  title: string
  performanceLink?: string
  subText?: string
  secondaryActionButton?: ReactNode
  reviewsPublishingDay?: string | null
  isCalibrationStep?: boolean
  summaryData?: ReviewSummaryInterface
}) => {
  const user = useSelector(selectUser)
  const tooltip = useTooltip()

  const isSkipedStatus = reviewsPublishingDay
    ? isCalibrationStep && isPast(new Date(reviewsPublishingDay))
    : false

  if (!reviews?.length) {
    return null
  }

  const getStatusSettings = (status?: Statuses) => {
    switch (status) {
      case Statuses.completed:
      case Statuses.completed_late:
      case Statuses.approved:
        return {
          color: Token.color.foreground,
          title: 'Completed',
        }
      case Statuses.rejected:
        return { color: Token.color.red, title: 'Rejected' }
      case Statuses.expired:
        return { color: Token.color.greyTone50, title: 'Expired' }
      default:
        return { color: Token.color.greyTone50, title: 'Pending' }
    }
  }

  const renderStatusField = (
    item: SingleTimelineEventReviewInterface | GradedRequestInterface,
    userIsReviewer: boolean,
  ) => {
    if (isSkipedStatus) {
      return null
    }

    if (userIsReviewer && item.status === Statuses.completed) {
      const grade = [
        ReviewerRelation.FunctionalManager,
        ReviewerRelation.LineManager,
      ].includes(item.reviewer_relation)
        ? item.reviewer_relation === ReviewerRelation.FunctionalManager
          ? summaryData?.summary?.functional_manager_extra_section
              ?.employee_project_performance?.value
          : summaryData?.summary?.line_manager_extra_section?.employee_project_performance
              ?.value
        : null
      return grade && summaryData?.grade_label_mapping ? (
        <Text variant="body1" color={getColorsAndGrade(grade)?.gradeColor}>
          {summaryData.grade_label_mapping[grade]}
        </Text>
      ) : (
        <Text variant="body1">Completed</Text>
      )
    }
    return (
      <Text variant="body1" color={getStatusSettings(item.status)?.color}>
        {getStatusSettings(item.status)?.title}
      </Text>
    )
  }

  return (
    <>
      {reviews.map(item => {
        const userIsReviewer = item.reviewer?.id === user.id
        const attitude = isObject(item.grade)
          ? getAboveBelowSuggestedGrades(item.grade.id, item.recommended_grade?.id)
          : 'neutral'
        return (
          <Item
            key={`${item.reviewer?.id}${item.reviewer_relation}`}
            py="s-8"
            pl="s-4"
            use={performanceLink ? 'button' : undefined}
            onClick={() => {
              if (performanceLink) {
                navigateTo(
                  pathToUrl(performanceLink, undefined, {
                    reviewer_id: item.reviewer.id.toString(),
                  }),
                )
              }
            }}
          >
            <Item.Avatar>
              <Avatar
                image={item.reviewer?.avatar}
                label={getInitials(item.reviewer?.full_name || '')}
              />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                {'is_delegated' in item && item.is_delegated
                  ? 'Calibrator'
                  : relationToString(item.reviewer_relation, false)}
              </Item.Title>
              <Item.Description>
                {item.reviewer?.id ? (
                  <StyledLink
                    onClick={e => e.stopPropagation()}
                    to={getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: item.reviewer?.id,
                      }),
                    )}
                  >
                    {chain(item.reviewer?.full_name, `${subText || ''}`)}
                  </StyledLink>
                ) : (
                  chain(item.reviewer?.full_name, `${subText || ''}`)
                )}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              {userIsReviewer && !buttonDisabled && !secondaryActionButton && (
                <ActionButton
                  useIcon={icon}
                  onClick={e => {
                    e.stopPropagation()
                    onClickAddReview(e)
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  {title}
                </ActionButton>
              )}
              {userIsReviewer && !buttonDisabled && secondaryActionButton}
              {(buttonDisabled || !userIsReviewer) && (
                <VStack>
                  {isObject(item.grade) ? (
                    <Tooltip
                      placement="left"
                      backgroundColor={Token.color.popoverBackground}
                      body={<CalibratedTooltipBody item={item} />}
                    >
                      <Flex alignItems="flex-end" flexDirection="column">
                        <HStack align="center" gap="s-4">
                          {attitude !== 'neutral' && (
                            <Icon
                              name={attitude === 'above' ? 'ArrowUp' : 'ArrowDown'}
                              size={16}
                              color={
                                attitude === 'above'
                                  ? Token.color.success
                                  : Token.color.red
                              }
                            />
                          )}
                          <Text variant="body1">{item.grade.label}</Text>
                        </HStack>
                        {attitude !== 'neutral' && (
                          <HStack gap="s-4" align="center" color={Token.color.greyTone50}>
                            <Icon name="InfoOutline" size={16} />
                            <Text variant="body2">
                              Calibrated {attitude === 'above' ? 'up' : 'down'}
                            </Text>
                          </HStack>
                        )}
                      </Flex>
                    </Tooltip>
                  ) : (
                    renderStatusField(item, userIsReviewer)
                  )}
                </VStack>
              )}
            </Item.Side>
          </Item>
        )
      })}
    </>
  )
}
