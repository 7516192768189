import React from 'react'
import { Flex, Placeholder } from '@revolut/ui-kit'

import Loader from '@src/components/CommonSC/Loader'
import { useQuery } from '@src/utils/queryParamsHooks'
import { toIdAndName } from '@src/utils/toIdAndName'
import { UseTimelineFilterReturnType } from '../ResultsTab/TimelineFilter/useTimelineFilter'

export const NoDataPlaceholder = () => (
  <Flex
    pb={96}
    flex="1"
    width="100%"
    height="100%"
    alignItems="flex-end"
    justifyContent="center"
  >
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D221.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@3x.png',
        }}
      />
      <Placeholder.Title>No data found</Placeholder.Title>
    </Placeholder>
  </Flex>
)

export const withLoader = (isLoading: boolean, widgetContent: React.ReactNode) => {
  if (isLoading) {
    return <Loader />
  }
  return widgetContent
}

export const timelineFilterToQueryParams = (
  timelineFilter: UseTimelineFilterReturnType,
) => {
  if (!timelineFilter) {
    return undefined
  }
  return {
    ...(timelineFilter.mode.value === 'rounds' && timelineFilter.round.value
      ? { survey_round_id: String(timelineFilter.round.value.id) }
      : null),
    from_date: timelineFilter.dateFrom,
    to_date: timelineFilter.dateTo,
  }
}

const filterQueryParamKeys = ['survey_round_id', 'from_date', 'to_date']

export const useDetailsFilterParamsFromQuery = () => {
  const { query } = useQuery()
  return Object.fromEntries(
    filterQueryParamKeys
      .filter(key => Object.keys(query).includes(key))
      .map(key => [key, query[key]]),
  )
}

export const useDetailsCommentsFilterParamsFromQuery = () => {
  const filtersParams = useDetailsFilterParamsFromQuery()
  return filterQueryParamKeys
    .filter(key => Object.keys(filtersParams).includes(key))
    .map(filterKey => ({
      columnName: filterKey,
      filters: [toIdAndName(filterKey)],
      nonResettable: true,
    }))
}
