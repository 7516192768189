import React from 'react'
import {
  Avatar,
  Box,
  Button,
  ButtonSkeleton,
  Group,
  Header,
  HeaderSkeleton,
  Icon,
  IconName,
  Item,
  ItemProps,
  ItemSkeleton,
  Popup,
  Subheader,
  SubheaderSkeleton,
} from '@revolut/ui-kit'
import {
  MeetingEvent,
  MeetingTemplateInterface,
  OneToOneMeeting,
} from '@src/interfaces/meetings'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { getTotalTimeFromMinutes } from '@src/utils/format'
import { formatMeetingEventTimeDiff } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/meetingSeries'
import { UserAvatar } from '@components/UserWithAvatar/UserAvatar'
import {
  getSelectEventDescription,
  getTitle,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/constants'
import {
  defaultOneToOneMeetingDuration,
  defaultOneToOneMeetingFrequency,
  meetingFrequencyToLabelMap,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/meeting'

const getDescription = (
  employee: EmployeeOptionInterface,
  events: MeetingEvent[],
  template?: MeetingTemplateInterface,
) => {
  if (events.length > 0) {
    return getSelectEventDescription(employee)
  }

  const frequency = template
    ? meetingFrequencyToLabelMap[template.frequency]
    : defaultOneToOneMeetingFrequency
  const duration = template ? template.duration : defaultOneToOneMeetingDuration

  return `We recommend a ${frequency.toLowerCase()} ${getTotalTimeFromMinutes(
    duration,
  )} meeting`
}

interface Props {
  isLoading: boolean
  meeting: OneToOneMeeting
  events: MeetingEvent[]
  template?: MeetingTemplateInterface
  handleSelectEvent: (event: MeetingEvent) => void
  handleCreateEvent: VoidFunction
  handleCancel: VoidFunction
}

export const SelectEventBody = ({
  isLoading,
  meeting,
  events,
  template,
  handleCreateEvent,
  handleSelectEvent,
  handleCancel,
}: Props) => {
  const title = getTitle(meeting.employee)
  const description = getDescription(meeting.employee, events, template)

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
      </Header>
      {events.length > 0 && (
        <>
          <Subheader>
            <Subheader.Title>Existing meeting</Subheader.Title>
            <Subheader.Side>
              <Icon name="LogoGoogle|image" size={16} />
            </Subheader.Side>
          </Subheader>
          <Group>
            {events.map(event => (
              <MeetingItem
                key={event.id}
                title={event.title}
                description={formatMeetingEventTimeDiff(event)}
                employee={meeting.employee}
                onClick={() => handleSelectEvent(event)}
              />
            ))}
          </Group>
        </>
      )}
      <Subheader>
        <Subheader.Title>Create a new event</Subheader.Title>
      </Subheader>
      <MeetingItem
        title="Find a time slot"
        iconName="Calendar"
        variant="disclosure"
        onClick={handleCreateEvent}
      />
      <Popup.Actions>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Popup.Actions>
    </>
  )
}

const Skeleton = () => (
  <>
    <HeaderSkeleton variant="bottom-sheet" />
    <SubheaderSkeleton />
    <Group>
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
    <SubheaderSkeleton>
      <SubheaderSkeleton.Title />
    </SubheaderSkeleton>
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
      </ItemSkeleton.Content>
    </ItemSkeleton>
    <Box pt="s-16">
      <ButtonSkeleton />
    </Box>
  </>
)

interface MeetingItemProps extends ItemProps {
  title: string
  description?: string
  iconName?: IconName
  employee?: EmployeeOptionInterface
  onClick: VoidFunction
}

const MeetingItem = ({
  title,
  description,
  iconName,
  employee,
  onClick,
  ...itemProps
}: MeetingItemProps) => (
  <Item use="button" {...itemProps} onClick={onClick}>
    <Item.Avatar>
      {employee !== undefined ? (
        <UserAvatar {...employee} size={40} />
      ) : (
        <Avatar useIcon={iconName} />
      )}
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      {description && <Item.Description>{description}</Item.Description>}
    </Item.Content>
  </Item>
)
