import React from 'react'
import { useQueryClient } from 'react-query'

import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { PermissionTypes } from '@src/store/auth/types'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const TeamSettingsFields = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = !useHasGlobalPermission([
    PermissionTypes.ChangeOrganisationPreferences,
  ])

  return (
    <>
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Allow team owners to view salaries',
          description:
            'Allows the team owners to view the salaries of the people in their team',
        }}
        name="enable_team_owners_view_salaries"
        disabled={disableEdit}
      />
      <ApprovalSteps
        title="Enable team approval"
        description="Whenever a new team is created or an existing one is changed it asks for approval"
        switchField="enable_teams_approvals"
        approvalProcess="teams_approval_steps"
        entity="team"
        disabled={disableEdit}
        formRef={values}
      />
    </>
  )
}

export const TeamSettingsSaveButton = () => {
  const queryClient = useQueryClient()

  return (
    <NewSaveButtonWithPopup
      successText="Settings saved successfully"
      onAfterSubmit={() => {
        queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
        queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
      }}
      useValidator
    />
  )
}
