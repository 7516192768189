import { MeetingTemplateFrequency } from '@src/interfaces/meetings'
import { meetingRecurrence } from '@src/interfaces/meetingsTracker'

export enum OneToOneStatus {
  Missing = 'Missing',
  Scheduled = 'Scheduled',
}

export const meetingFrequencyToRecurrenceMap: Record<
  MeetingTemplateFrequency,
  meetingRecurrence
> = {
  [MeetingTemplateFrequency.MONTHLY]: meetingRecurrence.Monthly,
  [MeetingTemplateFrequency.WEEKLY]: meetingRecurrence.Weekly,
  [MeetingTemplateFrequency.BI_WEEKLY]: meetingRecurrence.BiWeekly,
}

export const defaultOneToOneMeetingFrequency = MeetingTemplateFrequency.WEEKLY
export const defaultOneToOneMeetingDuration = 30

export const meetingFrequencyToLabelMap: Record<MeetingTemplateFrequency, string> = {
  [MeetingTemplateFrequency.MONTHLY]: 'Monthly',
  [MeetingTemplateFrequency.WEEKLY]: 'Weekly',
  [MeetingTemplateFrequency.BI_WEEKLY]: 'Bi-weekly',
}
