import React, { useRef } from 'react'
import { Grid, HStack, Icon, List, VStack, Text, Tag, Token } from '@revolut/ui-kit'
import { GoalsInterface } from '@src/interfaces/goals'
import { LocationDescriptor } from 'history'
import styled from 'styled-components'
import { isCascadedGoal } from '@src/pages/Forms/GoalForm/common/utils'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import StyledLink from '@src/components/Atoms/StyledLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Statuses } from '@src/interfaces'
import { GoalName } from './GoalName'
import Tooltip from '@src/components/Tooltip/Tooltip'
import TableCellLink from '../TableCellLink/TableCellLink'
import { CellTypes } from '@src/interfaces/data'

const CustomLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & > a {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

interface Props {
  data: GoalsInterface
  getGoalUrl?: (goal: GoalsInterface) => string | LocationDescriptor<unknown>
}

export const GoalNameCell = ({ data, getGoalUrl }: Props) => {
  const isCascaded = isCascadedGoal(data.update_type)

  const gridRef = useRef<HTMLDivElement>(null)
  const path = getGoalUrl
    ? getGoalUrl(data)
    : pathToUrl(
        data.status.id === Statuses.draft
          ? ROUTES.FORMS.GOAL.EDIT
          : ROUTES.FORMS.GOAL.PREVIEW,
        { id: data.id },
      )
  return isCascaded ? (
    // this all is by design. It has to be flex in order to be expanded beyond the cell on hover
    // but when we want to have a cascaded tooltip in static place and the hover over the badge do not extend the cell
    // we do this magic below. Tried different solutions with absolutes but coloring breakes and to make it work is a lot of logic
    // so this is the cleanest solution really (except for having it in another column but that way when goal is not cascaded we have some extra space for the name)
    <Grid
      columns="minmax(10px, 1fr) auto"
      gap="s-4"
      ref={gridRef}
      onMouseLeave={() => {
        if (gridRef.current) {
          gridRef.current.style.display = 'grid'
          gridRef.current.style.justifyContent = 'unset'
        }
      }}
    >
      <CustomLinkWrapper
        onMouseEnter={() => {
          if (gridRef.current) {
            gridRef.current.style.display = 'flex'
            gridRef.current.style.justifyContent = 'space-between'
          }
        }}
      >
        <StyledLink
          to={path}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <GoalName name={data.name} />
        </StyledLink>
      </CustomLinkWrapper>

      <Tooltip
        body={
          <VStack gap="s-6" space="s-4" p="s-12" width="max-content" minWidth={200}>
            <List variant="compact">
              <List.Item>
                <Text variant="emphasis2" color={Token.color.foreground}>
                  Cascaded goal
                </Text>
              </List.Item>
              <List.Item>
                <Text variant="body3" color={Token.color.greyTone50}>
                  This goal is linked to a higher-level goal and reflects any updates made
                  to it. It cannot be edited directly.
                </Text>
              </List.Item>

              <List.Item>
                <Text variant="body3" color={Token.color.greyTone50}>
                  Original goal:
                </Text>
              </List.Item>

              <List.Item>
                <HStack gap="s-4" align="center">
                  <Text variant="emphasis2" color={Token.color.foreground}>
                    {data.parent?.name}
                  </Text>
                  {data.parent?.id && (
                    <Icon
                      use={InternalLink}
                      to={pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, {
                        id: data.parent.id,
                      })}
                      name="LinkExternalSimple"
                      size={16}
                      color={Token.color.deepGrey}
                    />
                  )}
                </HStack>
              </List.Item>

              {data.parent?.content_object?.name && (
                <List.Item>
                  <HStack color={Token.color.foreground} align="center" gap="s-4">
                    <Icon name="People" size={16} />
                    <Text>{data.parent.content_object.name} (Team)</Text>
                  </HStack>
                </List.Item>
              )}

              {data.parent?.owner?.full_name && (
                <List.Item>
                  <HStack color={Token.color.foreground} align="center" gap="s-4">
                    <Icon name="Profile" size={16} />
                    <Text>{data.parent.owner.full_name} (Owner)</Text>
                  </HStack>
                </List.Item>
              )}
            </List>
          </VStack>
        }
        backgroundColor={Token.color.popoverBackground}
        placement="right"
      >
        <Tag
          useIcon="Materials"
          variant="faded"
          color={Token.color.deepGrey}
          bg={Token.color.deepGrey_20}
        />
      </Tooltip>
    </Grid>
  ) : (
    <TableCellLink to={path} cellType={CellTypes.insert}>
      <GoalName name={data.name} />
    </TableCellLink>
  )
}
