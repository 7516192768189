import { Text, Token } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  MeetingTemplateInterface,
  MeetingTemplateStatus,
  OneToOneMeeting,
} from '@src/interfaces/meetings'
import {
  MeetingTemplatesQueryKeys,
  OneToOneMeetingsFiltersQueryKeys,
  OneToOneMeetingsQueryKeys,
} from '@src/api/meetings'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { oneToOneModel } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/models/oneToOneModel'
import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { employeeRelationToLabelMap } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/constants'
import { formatRecurrentTimeDiff } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/meetingSeries'
import { formatRRule } from '@src/utils/format'
import {
  meetingFrequencyToLabelMap,
  OneToOneStatus,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/meeting'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'
import React from 'react'

export const employeeColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: OneToOneMeetingsFiltersQueryKeys.ParticipantId,
  dynamicHyperlinks: meeting =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
      id: meeting.employee.id,
    }),
  insert: ({ data: meeting }) => <Table.EmployeeCell employee={meeting.employee} />,
  selectorsKey: selectorKeys.all_employees,
  title: 'Employee',
}

export const relationColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.EmployeeRelationType,
  dataPoint: OneToOneMeetingsQueryKeys.EmployeeRelationType,
  sortKey: OneToOneMeetingsQueryKeys.EmployeeRelationType,
  filterKey: OneToOneMeetingsQueryKeys.EmployeeRelationType,
  colors: () => Token.color.greyTone50,
  insert: ({ data: meeting }) =>
    employeeRelationToLabelMap[meeting.employee_relation_type],
  selectorsKey: selectorKeys.meeting_employee_relation_types,
  title: 'Relation',
}

export const timeColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: 'time',
  dataPoint: 'time',
  sortKey: null,
  filterKey: null,
  colors: () => Token.color.greyTone50,
  insert: ({ data: meeting }) => formatRecurrentTimeDiff(meeting),
  selectorsKey: selectorKeys.none,
  title: 'Time',
}

export const recurrenceColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: 'recurrence',
  dataPoint: 'recurrence',
  sortKey: null,
  filterKey: null,
  colors: meeting =>
    !oneToOneModel.isScheduled(meeting) ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: meeting }) =>
    oneToOneModel.isScheduled(meeting)
      ? formatRRule(meeting.meeting_series?.recurrence)
      : 'N/A',
  selectorsKey: selectorKeys.none,
  title: 'Recurring',
}

export const statusColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.NoMeeting,
  dataPoint: OneToOneMeetingsQueryKeys.NoMeeting,
  sortKey: OneToOneMeetingsQueryKeys.NoMeeting,
  filterKey: OneToOneMeetingsQueryKeys.NoMeeting,
  insert: ({ data: meeting }) => {
    const isScheduled = oneToOneModel.isScheduled(meeting)

    const status = isScheduled ? OneToOneStatus.Scheduled : OneToOneStatus.Missing
    const variant: ColorTagVariant = isScheduled ? 'neutral' : 'danger'

    return <Table.StatusCell status={status} variant={variant} />
  },
  selectorsKey: async () => ({
    options: [
      { id: 'true', name: OneToOneStatus.Missing },
      { id: 'false', name: OneToOneStatus.Scheduled },
    ],
  }),
  title: 'Status',
}

export const companyMeetingOwnerColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.ManagerId,
  dataPoint: 'manager.full_name',
  sortKey: OneToOneMeetingsQueryKeys.ManagerFullName,
  filterKey: OneToOneMeetingsQueryKeys.ManagerId,
  selectorsKey: selectorKeys.all_employees,
  title: 'Meeting owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.manager} />,
}

export const companyMeetingDepartmentnColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.text,
  idPoint: 'manager.department.id',
  dataPoint: 'manager.department.name',
  sortKey: OneToOneMeetingsQueryKeys.ManagerTeamDepartmentName,
  filterKey: OneToOneMeetingsQueryKeys.ManagerTeamDepartmentId,
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const companyMeetingRelationColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.RelationType,
  dataPoint: OneToOneMeetingsQueryKeys.RelationType,
  sortKey: OneToOneMeetingsQueryKeys.RelationType,
  filterKey: OneToOneMeetingsQueryKeys.RelationType,
  selectorsKey: selectorKeys.meeting_relation_types,
  title: 'Relation',
  colors: () => Token.color.greyTone50,
  insert: ({ data }) => employeeRelationToLabelMap[data.relation_type],
}

export const companyEmployeeColumn: ColumnInterface<OneToOneMeeting> = {
  type: CellTypes.insert,
  idPoint: OneToOneMeetingsQueryKeys.EmployeeId,
  dataPoint: 'employee.full_name',
  sortKey: OneToOneMeetingsQueryKeys.EmployeeFullName,
  filterKey: OneToOneMeetingsQueryKeys.EmployeeId,
  selectorsKey: selectorKeys.all_employees,
  title: 'Report',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
}

export const templateNameColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: MeetingTemplatesQueryKeys.TemplateName,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Templates',
  insert: ({ data }) => (
    <Table.ItemCell useIcon="DocumentsPair">
      <Table.ItemCell.Title>
        <Text fontSize={Token.fontSize.emphasis1}>{data.name}</Text>
        {data.status === MeetingTemplateStatus.Draft && (
          <Text ml="s-4" color={Token.color.greyTone50}>
            (Draft)
          </Text>
        )}
      </Table.ItemCell.Title>
    </Table.ItemCell>
  ),
}

export const templateAudienceColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'audiences.id',
  dataPoint: 'audiences.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Audience',
  insert: ({ data }) => (
    <Text color={Token.color.greyTone50}>
      {data.audiences.length > 0 ? data.audiences.map(item => item.name).join(', ') : '-'}
    </Text>
  ),
}

export const templateDurationColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: MeetingTemplatesQueryKeys.Duration,
  dataPoint: MeetingTemplatesQueryKeys.Duration,
  sortKey: MeetingTemplatesQueryKeys.Duration,
  filterKey: MeetingTemplatesQueryKeys.Duration,
  title: 'Default Duration',
  selectorsKey: selectorKeys.feedback_template_duration,
  insert: ({ data }) => (
    <Text color={Token.color.greyTone50}>
      {data?.duration ? `${data.duration} min` : '-'}
    </Text>
  ),
}

export const templateFrequencyColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: MeetingTemplatesQueryKeys.Frequency,
  dataPoint: MeetingTemplatesQueryKeys.Frequency,
  sortKey: MeetingTemplatesQueryKeys.Frequency,
  filterKey: MeetingTemplatesQueryKeys.Frequency,
  title: 'Default Frequency',
  selectorsKey: selectorKeys.feedback_template_frequency,
  colors: () => Token.color.greyTone50,
  insert: ({ data }) => meetingFrequencyToLabelMap[data.frequency],
}

export const templateCreatedByColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'created_by.id',
  dataPoint: 'created_by.name',
  sortKey: MeetingTemplatesQueryKeys.CreatedBy,
  filterKey: MeetingTemplatesQueryKeys.CreatedBy,
  selectorsKey: selectorKeys.all_employees,
  title: 'Created By',
  insert: ({ data }) => <Table.EmployeeCell employee={data.created_by} />,
}

export const templateInputFieldsColumn: ColumnInterface<MeetingTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'items.id',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Input Fields',
  insert: ({ data }) => <Text>{data.items.map(item => item.title).join(', ')}</Text>,
}
