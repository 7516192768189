import React from 'react'
import { Avatar, HStack, Item, Tag, Text, Token, VStack } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

export const StructuresSettingsAll = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Company structure"
        subtitle="Define the arrangement of employees within your organization to manage the hierarchy of goals and relationships."
        backUrl={ROUTES.SETTINGS.ALL}
      />
      <PageBody>
        <VStack space="s-16">
          <Item
            use={InternalLink}
            to={ROUTES.SETTINGS.STRUCTURES.TEAMS.ALL}
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="Bank" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <HStack space="s-8">
                  <Text>Teams</Text>
                  <MandatoryTag />
                </HStack>
              </Item.Title>
              <Item.Description>
                Define areas of responsibility for deliverables in your business and use
                them to manage goals, talent, and performance reviews.
              </Item.Description>
            </Item.Content>
          </Item>

          <Item
            use={InternalLink}
            to={ROUTES.SETTINGS.STRUCTURES.ROLES.ALL}
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="RepairTool" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <HStack space="s-8">
                  <Text>Roles</Text>
                  <MandatoryTag />
                </HStack>
              </Item.Title>
              <Item.Description>
                Define areas of technical competencies to provide a clear view of
                expectations to use in hiring and performance management.
              </Item.Description>
            </Item.Content>
          </Item>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

const MandatoryTag = () => (
  <Tag variant="outlined" color={Token.color.deepGrey}>
    Mandatory
  </Tag>
)
