import React, { useEffect, useState } from 'react'
import PublicPageLayout from '@src/components/CommonSC/PublicPageLayout'
import {
  getAvailableRecruiterSlotsForBookingLink,
  submitProfileBookCall,
  useGetBookingLinkByToken,
  useGetBookingLinkCompany,
  useGetBookingLinkTimeZones,
} from '@src/api/bookingLinks'
import Loader from '@components/CommonSC/Loader'
import { ContactInformationInterface } from '@src/interfaces/bookingLinks'
import { Box, H4, Skeleton, Token, VStack } from '@revolut/ui-kit'
import { Route, Switch, useParams } from 'react-router-dom'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import ContactInformation from '@src/features/BookingLinks/Candidate/ContactInformation'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import ExpiredLink from '@src/features/BookingLinks/Candidate/ExpiredLink'
import { CandidateSelectSlots } from '@src/pages/CandidateScheduling/CandidateSelectSlots'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import {
  validateContactInformation,
  ContactInformationErrors,
} from '@src/features/BookingLinks/Candidate/validation'
import ConfirmedAppointment from '@src/pages/CandidateScheduling/ConfirmedAppointment'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { DynamicAvailableCandidateSlot } from '@src/interfaces/interviewTool'

type BookingLinkParams = {
  token: string
}

const BookingLink = () => {
  const { sendAnalyticsEvent } = useAnalytics()
  const { token } = useParams<BookingLinkParams>()
  const {
    data: bookingLinkData,
    isLoading: loadingBookingLink,
    isError: bookingLinkError,
  } = useGetBookingLinkByToken(token)
  const bookingLinkDefinition = bookingLinkData?.booking_link_definition
  const [contactInformation, setContactInformation] =
    useState<ContactInformationInterface>({
      full_name: '',
      email: '',
    })
  const [contactInformationErrors, setContactInformationErrors] =
    useState<ContactInformationErrors>({
      full_name: '',
      email: '',
      valid: true,
    })
  const [appointmentDate, setAppointmentDate] = useState<string | null>(null)
  const [timeZone, setTimeZone] = useState<string>()

  const { data: timeZoneOptions, isLoading: timeZonesLoading } =
    useGetBookingLinkTimeZones(token)
  const { data: company, isLoading: isCompanyLoading } = useGetBookingLinkCompany(token)

  const generalUrl = pathToUrl(PUBLIC_ROUTES.BOOKING_LINK.GENERAL, { token })
  const selectUrl = pathToUrl(PUBLIC_ROUTES.BOOKING_LINK.SELECT, { token })

  const onValidate = (newContactInformation?: ContactInformationInterface): boolean => {
    const validation = validateContactInformation(
      newContactInformation ?? contactInformation,
    )
    setContactInformationErrors(validation)
    return validation.valid
  }

  const onSubmit = async (newTimeZone: string, slot: DynamicAvailableCandidateSlot) => {
    const isValid = onValidate()
    if (isValid) {
      await submitProfileBookCall(token, {
        ...contactInformation!,
        scheduling_timezone: timeZoneOptions?.options?.find(t => t.id === newTimeZone)!,
        consent_to_re_engage: !!contactInformation.consent_to_re_engage,
        event_date_time: slot.event_start_datetime,
      })
      setTimeZone(newTimeZone)
      setAppointmentDate(slot.event_start_datetime)
      sendAnalyticsEvent(AnalyticsEvents.booking_link_sent_by_candidate)
    } else {
      navigateTo(generalUrl)
    }
  }

  const tabs = [
    {
      title: 'Contact information',
      path: PUBLIC_ROUTES.BOOKING_LINK.GENERAL,
      to: generalUrl,
      component: () => (
        <ContactInformation
          contactInformation={contactInformation}
          contactInformationErrors={contactInformationErrors}
          onChange={(newContactInformation, newContactInformationErrors) => {
            setContactInformation({
              ...contactInformation,
              ...newContactInformation,
            })
            if (newContactInformationErrors) {
              setContactInformationErrors({
                ...contactInformationErrors,
                ...newContactInformationErrors,
              })
            }
          }}
          onSubmit={() => {
            if (onValidate()) {
              navigateTo(selectUrl)
            }
          }}
        />
      ),
    },
    {
      title: 'Timeslots',
      path: PUBLIC_ROUTES.BOOKING_LINK.SELECT,
      to: selectUrl,
      component: () => (
        <CandidateSelectSlots<DynamicAvailableCandidateSlot>
          noPadding
          confirmAppointment={false}
          interview={
            bookingLinkDefinition
              ? {
                  duration: bookingLinkDefinition?.specialisation_hiring_stage?.duration,
                  durationUnit:
                    bookingLinkDefinition?.specialisation_hiring_stage?.duration_unit,
                  recruiterEmail: bookingLinkDefinition?.job_posting?.recruiter?.email,
                  title: bookingLinkDefinition?.specialisation_hiring_stage?.title,
                  timeZoneId: timeZone,
                }
              : undefined
          }
          isLoadingInterview={loadingBookingLink}
          isLoadingTimeZones={timeZonesLoading}
          timeZones={timeZoneOptions}
          token={token}
          onCreateCandidateAppointment={onSubmit}
          onGetAvailableCandidateSlots={async (_, page) => {
            const res = await getAvailableRecruiterSlotsForBookingLink(token, page)
            return {
              slots: res.data,
              count: res.data.length,
            }
          }}
          onSlotChange={() => {
            sendAnalyticsEvent(
              AnalyticsEvents.booking_link_timeslot_selected_by_candidate,
            )
          }}
        />
      ),
    },
  ]

  useEffect(() => {
    sendAnalyticsEvent(AnalyticsEvents.booking_link_opened_by_candidate)
  }, [])

  const renderTitle = () => {
    if (isCompanyLoading) {
      return <Skeleton />
    }

    let res = ''

    if (bookingLinkDefinition?.job_posting?.name) {
      res += bookingLinkDefinition.job_posting.name

      if (company?.company_name) {
        res += ` at ${company.company_name}`
      }
    } else if (company?.company_name) {
      res += company.company_name
    }

    return res
  }

  if (loadingBookingLink) {
    return <Loader />
  }

  if (appointmentDate && timeZone) {
    return (
      <PublicPageLayout noPadding>
        <ConfirmedAppointment date={appointmentDate} timeZone={timeZone} />
      </PublicPageLayout>
    )
  }

  return (
    <PublicPageLayout noPadding>
      <VStack
        maxWidth={600}
        gap="s-16"
        px="s-16"
        pt="s-32"
        color={Token.color.foreground}
      >
        {!loadingBookingLink && bookingLinkData && (
          <>
            <H4>{renderTitle()}</H4>
            {bookingLinkData.is_valid && (
              <>
                <Box>
                  <TabBarNavigation tabs={tabs} />
                </Box>
                <Switch>
                  {tabs.map(tab => (
                    <Route path={tab.path} key={tab.path}>
                      {tab.component}
                    </Route>
                  ))}
                </Switch>
              </>
            )}
            {!bookingLinkData.is_valid && (
              <ExpiredLink
                recruiterEmail={bookingLinkDefinition?.job_posting?.recruiter?.email}
              />
            )}
          </>
        )}
        {bookingLinkError && <ExpiredLink />}
      </VStack>
    </PublicPageLayout>
  )
}

export default BookingLink
